<template>
  <div>
    <!-- <Handle type="target" :position="Position.Top" /> -->
    <div class="custom-node nodes add-new-trigger" v-if="data?.type == 'addNewTrigger' && data?.add">
      <span class="custom-node-label d-flex align-items-center">
        <span class="btn mb-0 add-trigger-btn me-2">
          <i :class="data.icon"></i>
        </span>
        <span class="text-ellipsis"> {{ data.title || label }}</span>
      </span>
    </div>
    <div class="custom-node nodes" :id="'node_'+id" :class="selectedData?.id == id ? 'active' : ''"
      v-else-if="data?.type == 'addNewTrigger' && !data?.add">
      <span class="custom-node-label d-flex align-items-center">
        <span class="btn mb-0 bg-light add-trigger-btn me-2"> <i :class="data.icon"></i></span>
        <span class="text-ellipsis custom-node-label-inner">
          <div class="text-custom font-600">Trigger</div>
          <div class="text-break" style="font-size:12px">{{ data.title || label }}</div>
        </span>
      </span>
    </div>
    <div class="custom-node end-trigger" v-else-if="data?.type == 'EndTrigger'">
      <span class="custom-node-label">
        <span class="border custom-node-label-inner" style="padding: 4px 14px; border-radius: 40vh"> {{ label }}</span>
      </span>
    </div>
    <div v-else class="custom-node position-relative" :id="'node_'+id"
      :class="data?.type == 'addNewAction' && data?.add ? 'add-action' : selectedData?.id == id ? 'active nodes ' : 'nodes'">
      <span class="custom-node-label" style="" v-if="data?.type == 'addNewAction' && data?.add">
        <span class="bg-white bordercustom-node-label-inner d-flex align-items-center justify-content-center"
          style="width: 25px; height: 25px; border-radius: 50%">
          <i :class="data.icon"></i> </span>
      </span>
      <span class="custom-node-label d-flex align-items-center"  v-else>
        <span class="btn mb-0 add-trigger-btn me-2" :class="data.name == 'modalai' ? 'modal-ai-ico' : ''">
          <img style=" max-width: 24px" src="@/assets/img/chatgpt.svg" v-if="data.name == 'chatgpt'" />
          <svg v-else-if="data.name == 'modalai'" style="width: 16px; height: 16px; object-fit: cover;" class="my-2"
            viewBox="0 0 513 421" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path id="SymbolLogo"
              d="M486.367 5.66518C470.401 -2.0562 451.334 -0.0486403 437.383 10.7613L256.331 151.754L74.9685 10.298C60.8626 -0.357495 41.9513 -2.0562 26.1402 5.66518C10.4841 13.541 0.408447 29.6015 0.408447 47.2062V373.821C0.408447 391.58 10.4841 407.64 26.6052 415.516C33.1157 418.605 40.0912 420.149 46.9116 420.149C57.1423 420.149 67.218 416.752 75.5886 410.266L220.058 301.703C208.123 291.511 168.905 260.471 141.623 238.697L98.0651 274.06V149.746L175.88 210.9L254.781 275.605L255.091 275.296L437.228 410.266C451.179 421.23 470.246 423.238 486.212 415.516C502.178 407.795 512.408 391.58 512.408 373.821V47.2062C512.408 29.447 502.333 13.3866 486.367 5.66518ZM414.752 276.995L336.781 213.37L414.752 149.901V276.995Z"
              fill="currentColor" />
          </svg>
          <i v-else :class="data.icon"></i>
        </span>
        <span class="text-ellipsis custom-node-label-inner">
          <i class="fa fa-exclamation-triangle me-2 text-danger" v-if="isError"></i>
          <span v-if="['llama', 'claude', 'chatgpt', 'gemini'].includes(data?.name) && data?.serialNo">#{{data.serialNo}}</span>
          {{ data.title || label }}
        </span>
        <popper v-if="!data?.add" placement="right" @close:popper="actionSelected = ''" class="ms-auto"
          @click="$event.stopPropagation()">
          <i class="fa action-btn fa-ellipsis-h ms-auto"></i>
          <template #content="{ close }">
            <div class="popover-content node-manage-options bg-white rounded-2 inbox-nav-manage-options shadow"
              ref="popoverContent">
              <div class="col-12 container-fluid p-0">
                <div class="list-unstyled text-xs px-0 p-1 mb-0 popli">
                  <div class="px-0 mx-0 border-bottom">
                    <div class="px-2 py-1 d-flex align-items-center justify-content-between">
                      <a class="text-col-navlink text-xs text-nowrap text-ellipsis"
                        href="javascript:void(0)">Actions</a>
                      <i class="fa fa-times ms-2" @click="
                          actionSelected = '';
                          close();
                        "></i>
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="px-0 mx-0" @click="actionSelected = 'delete'">
                      <div class="py-2 px-3 d-flex align-items-center justify-content-between hover-highlight"
                        style="min-width: 100px">
                        <a class="text-col-navlink text-xs text-nowrap text-ellipsis"
                          href="javascript:void(0)">Delete</a>
                        <i class="fa fa-chevron-right text-xs ms-2"></i>
                      </div>
                    </div>
                    <div v-if="actionSelected == 'delete'" class="px-0 mx-0">
                      <div class="py-2 px-3 d-flex align-items-center justify-content-between hover-highlight" @click="
                          $emit('deleteAction', id);
                          close();
                        ">
                        <a class="text-col-navlink text-xs text-nowrap text-ellipsis" href="javascript:void(0)">Delete
                          Action</a>
                      </div>
                      <div class="py-2 px-3 d-flex align-items-center justify-content-between hover-highlight" @click="
                          $emit('deleteAllAction', id);
                          close();
                        ">
                        <a class="text-col-navlink text-xs text-nowrap text-ellipsis" href="javascript:void(0)">Delete
                          All Actions From Here</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </popper>
      </span>
    </div>
    <!-- <Handle type="source" :position="Position.Bottom" /> -->
  </div>
</template>

<script>
  export default {
    name: 'CustomNode',
    props: ['id', 'data', 'label', 'selectedData', 'name', 'position', 'nodes'],
    emits: ['deleteAction', 'deleteAllAction'],
    data() {
      return {
        actionSelected: '',
      };
    },
    mounted() {},
    computed: {
      isError() {
        let check = false;
        // console.log('this.data ', this.data)
        if (
          !this.data.add &&
          (
            ['chatgpt', 'claude', 'gemini', 'llama', 'modalai'].includes(this.data?.name) && this?.data?.prompt?.includes('ai.') ||
            ['createNote'].includes(this.data?.name) && this.data?.note?.includes('ai.')  ||
            [ 'autoReply'].includes(this.data?.name) && this.data?.message?.includes('ai.') 
          )
        ) {

          let placeholders = [] 
          if(['chatgpt', 'claude', 'gemini', 'llama', 'modalai'].includes(this.data?.name)) {
            placeholders = this.data.prompt.match(/{{[^}]+}}/g) || [];
          } else  if(['createNote'].includes(this.data?.name)) {
            placeholders = this.data.note.match(/{{[^}]+}}/g) || [];
          } else  {
            placeholders = this.data.message.match(/{{[^}]+}}/g) || [];
          }

          // Filter nodes based on the condition
          let filteredNodes = this.nodes.filter(
            (el) =>
              !el.data.add &&
              el.data.type === 'addNewAction' &&
              ['llama', 'claude', 'gemini', 'chatgpt'].includes(el.name)
          );

          // Create the list of expected prompts
          let prompts = filteredNodes.map((element) => {
            switch (element.name) {
              case 'llama':
                return element?.data?.serialNo ? `{{ai.llama_response.${element.data.serialNo}}}` : `{{ai.llama_response}}`;
              case 'claude':
                return  element?.data?.serialNo ? `{{ai.claude_response.${element.data.serialNo}}}` : `{{ai.claude_response}}`;
              case 'gemini':
                return  element?.data?.serialNo ? `{{ai.gemini_response.${element.data.serialNo}}}` : `{{ai.gemini_response}}`;
              case 'chatgpt':
                return  element?.data?.serialNo ? `{{ai.openai_response.${element.data.serialNo}}}` : `{{ai.openai_response}}`;
              default:
                return '';
            }
          });
          // console.log('prompts:', prompts);

          // Check if any placeholder is NOT in the prompts array
          let hasMissingPlaceholders = placeholders.some((item) => !prompts.includes(item));
          // console.log('hasMissingPlaceholders:', hasMissingPlaceholders);

          // If any placeholder is missing, set check to true
          check = hasMissingPlaceholders;
        }

        return check;
      },
    }

  };
</script>

<style scoped>
  .custom-node {
    font-size: 14px;
    padding: 14px 15px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #fff;
  }
  .custom-node.nodes {
    width: 250px;
  }
  .custom-node-label {
    font-size: 14px;
  }
  ::v-deep .custom-node .popper {
    /* margin-top: 5px!important; */
    /* margin-left: 50px!important; */
    transform: translate(262px, 18px) !important;
  }
  ::v-deep .popper .hover-highlight:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
    border-radius: 4px !important;
  }
  .add-new-trigger {
    border: 1px dashed #2463eb !important;
    color: #2463eb;
    font-weight: 600;
    background: #eef6ff;
  }
  .add-trigger-btn i {
    color: #2463eb;
  }
  .add-new-trigger .add-trigger-btn {
    background-color: #dbe9fe !important;
  }
  .add-action {
    height: 25px;
    width: 250px;
    padding: 0;
    background: transparent;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .end-trigger {
    width: 250px;
    background: transparent;
    border: 0;
    padding: 0;
    display: flex;
    justify-content: center;
  }
  .end-trigger .custom-node-label-inner {
    background: #d1d5da !important;
  }
  .text-custom {
    color: #2463eb;
  }
  .font-600 {
    font-weight: 600;
  }
  .add-trigger-btn {
    width: 40px;
    height: 45px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #f1f4f7;
    font-size: 16px;
  }
  .action-btn {
    color: #9ba3af;
  }
  .active {
    border: 1px solid #2463eb !important;
  }
  .add-trigger-btn.modal-ai-ico {
    width: unset !important;
    padding: 0px 9px !important;
  }
  .btn.mb-0.add-trigger-btn.me-2.modal-ai-ico svg {
    width: 22px !important; 
    color: var(--primary-600) !important;

  }
</style>
